import Footer from "./Footer";

const CenteredLayout = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className="flex h-screen flex-col items-center justify-center">
      <div className="flex justify-center p-6">
        <img
          className="w-14 invert lg:w-20"
          src="/hydra-logo-white.svg"
          alt="hydra newmedia GmbH"
        />
      </div>
      <div className={className}>
        <div className="w-96 rounded-md border border-slate-200 bg-slate-50 text-center text-black shadow-xl">
          {children}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CenteredLayout;
